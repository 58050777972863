<template>
  <BaseCard
    :caption-visible="false"
    :footer-visible="false"
  >
    <template #title>
      <span class="h3 font-weight-bold mb-0 text-uppercase">albaranes</span>
    </template>
    <DeliveryNotesTable />
  </BaseCard>
</template>

<script>
import DeliveryNotesTable from '@/components/delivery-notes/table/DeliveryNotesTable.vue'
import BaseCard from '@/components/ui/card/BaseCard.vue'

export default {
  components: { BaseCard, DeliveryNotesTable },
}
</script>

<style scoped>
</style>
